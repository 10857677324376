import React from "react"

import { Button, Group, Header, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { CircleX, Notebook } from "tabler-icons-react"

export type LayoutHeaderPropsType = {
  handleDisconnect: () => void
}

const LayoutHeader = ({ handleDisconnect }: LayoutHeaderPropsType) => {
  const matches = useMediaQuery("(min-width: 450px)")

  return (
    <Header p="md" height={60}>
      <Group position="apart">
        <Group spacing={2} align="center" noWrap>
          <Notebook size={32} strokeWidth={2} color={"black"} />
          <Text weight="bold" size="xl">
            Les QR de Stef 📷
          </Text>
        </Group>
        <Button
          color="red"
          variant="outline"
          onClick={handleDisconnect}
          leftIcon={<CircleX />}
        >
          {matches && "Se déconnecter"}
        </Button>
      </Group>
    </Header>
  )
}

export default LayoutHeader
