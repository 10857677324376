import React, { useState } from "react"

import {
  Button,
  Container,
  Group,
  Input,
  Modal,
  Stack,
  Table,
} from "@mantine/core"
import { Link } from "react-router-dom"
import { Edit, Plus, Qrcode } from "tabler-icons-react"

import useCreateBook from "../../../hooks/api/Books/useCreateBook"
import useGetBooks, { Book } from "../../../hooks/api/Books/useGetBooks"
import useUpdateBook from "../../../hooks/api/Books/useUpdateBooks"

const CREATE_ID = "new"

const Books = () => {
  const [editedBookId, setEditedBookId] = useState<string | null>(null)
  const [editedBookNewName, setEditedBookNewName] = useState<string>("")

  const { data } = useGetBooks()
  const { mutate: updateBook } = useUpdateBook()
  const { mutate: createBook } = useCreateBook()

  const openEditModal = (book: Book) => {
    setEditedBookNewName(book.name)
    setEditedBookId(book.id)
  }

  const openCreationModal = () => {
    setEditedBookNewName("")
    setEditedBookId(CREATE_ID)
  }

  return (
    <Container size="md">
      <Group position="right">
        <Button color="lime" onClick={openCreationModal} leftIcon={<Plus />}>
          Ajouter un livre
        </Button>
      </Group>
      <Table highlightOnHover>
        <thead>
          <tr>
            <th>N°</th>
            <th>Nom</th>
            <th style={{ textAlign: "right" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.data.map((book: Book) => (
            <tr key={book.id}>
              <td>{book.id}</td>
              <td>{book.name}</td>
              <td>
                <Group position="right">
                  <Link to={`/book/${book.id}`}>
                    <Button color="lime" leftIcon={<Qrcode />}>
                      Voir les QR codes
                    </Button>
                  </Link>
                  <Button
                    onClick={() => openEditModal(book)}
                    leftIcon={<Edit />}
                  >
                    Renommer
                  </Button>
                </Group>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        opened={!!editedBookId}
        onClose={() => setEditedBookId(null)}
        title="Nouveau nom"
      >
        {editedBookId && (
          <Stack align="stretch">
            <Input
              type="text"
              value={editedBookNewName}
              placeholder="Nom"
              onChange={(e: any) => setEditedBookNewName(e.target.value)}
            />
            <Button
              onClick={() => {
                editedBookId === CREATE_ID
                  ? createBook({ name: editedBookNewName })
                  : updateBook({
                      id: editedBookId,
                      data: { name: editedBookNewName },
                    })
                setEditedBookId(null)
              }}
            >
              Sauvegarder
            </Button>
          </Stack>
        )}
      </Modal>
    </Container>
  )
}

export default Books
