import React from "react"

import axios, { AxiosResponse } from "axios"
import { useMutation, useQueryClient } from "react-query"

export type useAuthPropsType = {
  onSuccess?: (
    data: AxiosResponse<any, any>
  ) => void | Promise<unknown> | undefined
  onError?: (error: unknown) => void | Promise<unknown> | undefined
}

const useAuth = ({ onSuccess, onError }: useAuthPropsType = {}) => {
  const queryClient = useQueryClient()

  const update = (data: { password: string }) => {
    return axios.post("/auth", data)
  }

  return useMutation(update, {
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries("auth")
      onSuccess?.(data)
    },
    onError,
  })
}

export default useAuth
