import React, { memo, useMemo, useRef, useState } from "react"

import {
  Button,
  Container,
  Group,
  Input,
  Modal,
  Stack,
  Table,
} from "@mantine/core"
import { QRCodeCanvas } from "qrcode.react"
import { Link, useParams } from "react-router-dom"
import { ArrowLeft, Edit, Plus } from "tabler-icons-react"

import useCreateQrCode from "../../../hooks/api/QrCodes/useCreateQrCode"
import useGetQrCodes, { QrCode } from "../../../hooks/api/QrCodes/useGetQrCodes"
import useUpdateQrCode from "../../../hooks/api/QrCodes/useUpdateQrCode"
import {
  GeneratedColorLabelBadge,
  stringToColour,
} from "../../GeneratedColorBadge"

const CREATE_ID = "new"

const QrCodes = () => {
  const [orderByAttribute, setOrderByAttribute] = useState<string>("id")
  const [orderByAsc, setOrderByAsc] = useState<boolean>(true)

  const [editedQrCodeId, setEditedQrCodeId] = useState<string | null>(null)
  const [editedQrCodeNewName, setEditedQrCodeNewName] = useState<string>("")
  const [editedQrCodeNewTag, setEditedQrCodeNewTag] = useState<string>("")
  const [editedQrCodeNewLink, setEditedQrCodeNewLink] = useState<string>("")

  const { id } = useParams()

  const orderBy = useMemo(
    () => `${orderByAsc ? "!" : ""}${orderByAttribute}`,
    [orderByAsc, orderByAttribute]
  )
  console.log(orderBy)

  const { data } = useGetQrCodes({
    bookId: id ?? "",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    orderBy,
  })
  const { mutate: updateQrCode } = useUpdateQrCode({ bookId: id ?? "" })
  const { mutate: createQrCode } = useCreateQrCode({ bookId: id ?? "" })

  const openEditModal = (qrCode: QrCode) => {
    setEditedQrCodeNewName(qrCode.name)
    setEditedQrCodeNewTag(qrCode.tag)
    setEditedQrCodeNewLink(qrCode.link)
    setEditedQrCodeId(qrCode.id)
  }

  const openCreationModal = () => {
    setEditedQrCodeNewName("")
    setEditedQrCodeNewLink("")
    setEditedQrCodeId(CREATE_ID)
  }

  return (
    <Container size="md">
      <Group position="apart">
        <Link to="/">
          <Button leftIcon={<ArrowLeft />}>Retour aux livres</Button>
        </Link>
        <Button onClick={openCreationModal} leftIcon={<Plus />}>
          Créer un nouveau QR Code
        </Button>
      </Group>
      <Table highlightOnHover>
        <thead>
          <tr>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOrderByAsc(v => !v)
                setOrderByAttribute("id")
              }}
            >
              N° {orderByAttribute === "id" ? (orderByAsc ? "^" : "v") : null}
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOrderByAsc(v => !v)
                setOrderByAttribute("name")
              }}
            >
              Nom{" "}
              {orderByAttribute === "name" ? (orderByAsc ? "^" : "v") : null}
            </th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOrderByAsc(v => !v)
                setOrderByAttribute("tag")
              }}
            >
              Tag {orderByAttribute === "tag" ? (orderByAsc ? "^" : "v") : null}
            </th>
            <th style={{ textAlign: "right" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.data.map((qrCode: QrCode) => (
            <tr key={qrCode.id}>
              <td>{qrCode.id}</td>
              <td>{qrCode.name}</td>
              <td>
                {qrCode.tag && <GeneratedColorLabelBadge label={qrCode.tag} />}
              </td>
              <td>
                <Group position="right">
                  <DownloadadbleQrCode qrCode={qrCode} />
                  <Button
                    onClick={() => openEditModal(qrCode)}
                    leftIcon={<Edit />}
                  >
                    Modifier
                  </Button>
                </Group>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        opened={!!editedQrCodeId}
        onClose={() => setEditedQrCodeId(null)}
        title="Nouveau nom"
      >
        {editedQrCodeId && !!id && (
          <Stack align="stretch">
            <Input
              type="text"
              value={editedQrCodeNewName}
              placeholder="Nom"
              onChange={(e: any) => setEditedQrCodeNewName(e.target.value)}
            />
            <Input
              type="text"
              value={editedQrCodeNewTag}
              placeholder="Tag"
              onChange={(e: any) => setEditedQrCodeNewTag(e.target.value)}
            />
            <Input
              type="text"
              value={editedQrCodeNewLink}
              placeholder="Lien associé"
              onChange={(e: any) => setEditedQrCodeNewLink(e.target.value)}
            />
            <Button
              onClick={() => {
                console.log("editedQrCodeId", editedQrCodeId)
                editedQrCodeId === CREATE_ID
                  ? createQrCode({
                      name: editedQrCodeNewName,
                      tag: editedQrCodeNewTag,
                      link: editedQrCodeNewLink,
                      bookId: id,
                    })
                  : updateQrCode({
                      id: editedQrCodeId,
                      data: {
                        name: editedQrCodeNewName,
                        tag: editedQrCodeNewTag,
                        link: editedQrCodeNewLink,
                        bookId: id,
                      },
                    })
                setEditedQrCodeId(null)
              }}
            >
              Sauvegarder
            </Button>
          </Stack>
        )}
      </Modal>
    </Container>
  )
}

export const DownloadadbleQrCode = ({ qrCode }: { qrCode: QrCode }) => {
  const qrCodeParentDiv = useRef<HTMLDivElement>(null)

  const downloadQrCode = (qrCode: QrCode) => {
    console.log(qrCodeParentDiv.current?.children)

    const canvas = qrCodeParentDiv.current?.children[0] as HTMLCanvasElement

    const tiffUrl = (canvas as HTMLCanvasElement)
      ?.toDataURL("image/tiff")
      .replace("image/tiff", "image/octet-stream")
    const downloadLink = document.createElement("a")
    downloadLink.href = tiffUrl
    downloadLink.download = `${qrCode.name}.tiff`
    console.log("pngUrl", tiffUrl)
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <div
      ref={qrCodeParentDiv}
      onClick={() => downloadQrCode(qrCode)}
      style={{ width: "4cm", height: "4cm", padding: "0.5cm" }}
    >
      <MemoizedQrCode id={qrCode.id} />
    </div>
  )
}

export const MemoizedQrCode = memo(({ id }: { id: string | number }) => {
  const url = useMemo(() => `http://s7f.fr/${id}`, [id])

  return (
    <QRCodeCanvas
      value={url}
      size={2048}
      bgColor="transparent"
      style={{ width: "100%", height: "100%" }}
      level="L"
    />
  )
})

export default QrCodes
