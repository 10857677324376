import React from "react"

import axios from "axios"
import { useMutation, useQueryClient } from "react-query"

export type UseCreateQrCodePropsType = {
  bookId: string
}

const useUpdateQrCode = (props?: UseCreateQrCodePropsType) => {
  const queryClient = useQueryClient()

  const update = ({
    id,
    data,
  }: {
    id: string
    data: { name: string; tag: string; link: string; bookId: string }
  }) => {
    return axios.patch(`/qrCodes/${id}`, data)
  }

  return useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries(`qrCodes${props?.bookId}`)
    },
  })
}

export default useUpdateQrCode
