import React from "react"

import axios from "axios"
import { useMutation, useQueryClient } from "react-query"

const useUpdateBook = () => {
  const queryClient = useQueryClient()

  const update = ({ id, data }: { id: string; data: { name: string } }) => {
    return axios.patch(`/book/${id}`, data)
  }

  return useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("books")
    },
  })
}

export default useUpdateBook
