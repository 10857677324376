import React from "react"

import axios from "axios"
import { useMutation, useQueryClient } from "react-query"

export type UseCreateQrCodePropsType = {
  bookId: string
}

const useCreateQrCode = (props?: UseCreateQrCodePropsType) => {
  const queryClient = useQueryClient()

  const create = (data: {
    name: string
    tag: string
    link: string
    bookId: string
  }) => {
    return axios.post(`/qrCodes`, data)
  }

  return useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries(`qrCodes${props?.bookId}`)
    },
  })
}

export default useCreateQrCode
