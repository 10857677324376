import React from "react"

import axios from "axios"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools" //Only included in dev mode

import LogInWall from "./components/LogInWall/LogInWall"
import UserAccessRouter from "./components/UserAccessRouter/UserAccessRouter"

const App = () => {
  const queryClient = new QueryClient()

  axios.defaults.baseURL =
    process.env.REACT_APP_CATALOG_API_URL || "https://api.s7f.fr"

  return (
    <QueryClientProvider client={queryClient}>
      <LogInWall>
        <UserAccessRouter />
      </LogInWall>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
