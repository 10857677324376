import React from "react"

import axios from "axios"
import { useQuery, useQueryClient } from "react-query"

export type UseGetBooksPropsType = {
  page?: number
  limit?: number
}

export type Book = {
  id: string
  name: string
}

const useGetBooks = (props?: UseGetBooksPropsType) => {
  return useQuery("books", () => axios.get("/book", { params: props }))
}

export default useGetBooks
