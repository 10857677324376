import React, { FC } from "react"

import { AppShell, Header } from "@mantine/core"

import LayoutHeader, {
  LayoutHeaderPropsType,
} from "./LayoutHeader/LayoutHeader"

type LayoutPropsType = {
  headerProps: LayoutHeaderPropsType
}

const Layout: FC<LayoutPropsType> = ({ headerProps, children }) => (
  <AppShell padding="md" header={<LayoutHeader {...headerProps} />}>
    {children}
  </AppShell>
)

export default Layout
