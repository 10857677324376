import React from "react"

import { Route, Routes } from "react-router-dom"

import Books from "../Pages/Books/Books"
import QrCodes from "../Pages/QrCodes/QrCodes"

const UserAccessRouter = () => {
  return (
    <Routes>
      <Route path="/book/:id" element={<QrCodes />} />
      <Route path="/*" element={<Books />} />
    </Routes>
  )
}

export default UserAccessRouter
