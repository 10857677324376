import React, { MouseEventHandler } from "react"

import { Badge, BadgeProps, Group } from "@mantine/core"

export type GeneratedColorLabelBadgePropsType = {
  label: string | JSX.Element
  colorSeed?: string
  onClick?: MouseEventHandler<HTMLDivElement>
  withCloseButton?: boolean
} & BadgeProps<"div">

export const GeneratedColorLabelBadge = ({
  label,
  colorSeed,
  color,
  ...props
}: GeneratedColorLabelBadgePropsType) => {
  const colorString =
    color ??
    stringToColour(
      typeof label === "string" ? label : colorSeed ? colorSeed : ""
    )

  return (
    <Badge
      style={{
        color: colorString,
        backgroundColor: `${colorString}18`,
        cursor: props.onClick ? "pointer !important" : "inherit",
        margin: "2px",
      }}
      {...props}
    >
      <Group align="center" position="center" spacing={2}>
        {label}
      </Group>
    </Badge>
  )
}

export const stringToColour = (str: string) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = "#"
  let values = []
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    values.push(value)
  }
  const originalValue = [...values]

  //Avoid too clear or too dark colors
  if (
    values.reduce((a, b) => a + b) > 450 ||
    values.reduce((a, b) => a + b) < 180
  ) {
    values = values.map(v => Math.max(v * 0.58, 75))
  }

  //Avoid greys
  if (new Set(values).size === 1) {
    values = values.map((v, index) => v + originalValue[index])
  }

  values.forEach(v => (colour += ("00" + v.toString(16)).substr(-2)))
  return colour
}
