import React, { FC, useEffect, useState } from "react"

import {
  Button,
  Card,
  Center,
  Container,
  PasswordInput,
  Stack,
} from "@mantine/core"
import axios, { AxiosResponse } from "axios"
import { Lock } from "tabler-icons-react"

import useAuth from "../../hooks/api/useAuth"
import Layout from "../Layout/Layout"

const LogInWall: FC<any> = ({ children }) => {
  const [password, setPassword] = useState("")
  const [token, setToken] = useState("")

  useEffect(() => {
    setToken(window.localStorage.getItem("token") ?? "")
  }, [])

  axios.defaults.headers.common["Authorization"] = `bearer ${token}`

  const connect = () => {
    mutate({ password: password })
  }

  const handleSuccessConnect = (data: AxiosResponse) => {
    console.log("data", data)
    const token = (data as any).token
    window.localStorage.setItem("token", token)
    setToken(token)
  }

  const disconnect = () => {
    setToken("")
    window.localStorage.setItem("token", "")
  }

  const { mutate } = useAuth({ onSuccess: handleSuccessConnect })

  return token ? (
    <Layout headerProps={{ handleDisconnect: disconnect }}>{children}</Layout>
  ) : (
    <Container p="lg">
      <Center>
        <Card
          shadow="md"
          withBorder
          color="white"
          p="lg"
          style={{ width: 400 }}
        >
          <Stack align="stretch" justify="center">
            <PasswordInput
              icon={<Lock />}
              placeholder="Mot de passe"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
            <Button onClick={connect}>Se connecter</Button>
          </Stack>
        </Card>
      </Center>
    </Container>
  )
}

export default LogInWall
