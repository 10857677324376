import React from "react"

import axios from "axios"
import { useMutation, useQueryClient } from "react-query"

const useCreateBook = () => {
  const queryClient = useQueryClient()

  const create = (data: { name: string }) => {
    return axios.post(`/book`, data)
  }

  return useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("books")
    },
  })
}

export default useCreateBook
