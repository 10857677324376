import React from "react"

import axios from "axios"
import { useQuery } from "react-query"

import { Book } from "../Books/useGetBooks"

export type UseGetQrCodesPropsType = {
  bookId: string
  orderBy: `${"!" | ""}${keyof QrCode}`
  page?: number
  limit?: number
}

export type QrCode = {
  id: string
  name: string
  tag: string
  link: string
  book: Book
}

const useGetQrCodes = (props?: UseGetQrCodesPropsType) => {
  return useQuery([`qrCodes${props?.bookId}`, props], () =>
    axios.get("/qrCodes", { params: props })
  )
}

export default useGetQrCodes
